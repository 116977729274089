import axios from 'axios';

const api = axios.create({
baseURL: 'https://api-sua-chave-auto-private.onrender.com'
// baseURL: 'http://localhost:3333/'
});

export default api;



